import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import {
  logout,
  selectLogin
} from '../redux/loginSlice';

import { useApolloClient } from '@apollo/client';


import ButtonAsLink from './ButtonAsLink';

const HeaderBar = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 10px;
  
  background-color: #222222;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.50);
`;

const HomeButton = styled.div`
  top: 30px;
  left: 1em;
  display: inline-flex;
  font-size: 18px;
`;


const UserState = styled.div`
  top: 30px;
  right: 1em;
  display: inline-flex;
  font-size: 18px;
`;


const Header = (props) => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectLogin);
  const client = useApolloClient();

  return (
    <HeaderBar> 
      <HomeButton>
        {isLoggedIn ? 
          (
            <ButtonAsLink onClick={ () => {
              props.history.push('/home');
            }}>
              Home 
            </ButtonAsLink>
          ) : ( 
            <></>          )
        }
      </HomeButton>
      <h1>
        vCue
      </h1>
      <UserState>
        {isLoggedIn ? 
          (
            <ButtonAsLink onClick={ () => {
              // Change the redux state
              client.resetStore();
              dispatch(logout());
              props.history.push('/');
            }}>
              Logout
            </ButtonAsLink>
          ) : ( 
            <></>          )
        }
      </UserState>
    </HeaderBar>
  )
}

export default withRouter(Header);
