import React, { useEffect, useState } from 'react';
import platform from 'platform';
import { useMutation } from '@apollo/client';
import {  useSelector, useDispatch } from 'react-redux';
import {
  login,
  selectLogin,
  selectPermissions
} from '../redux/loginSlice';

import { GET_INIT_DATA, NEW_OPERATION_LOG } from '../gql/mutation';

import Form from '../components/Form';
import Button from '../components/Button';
import CenteredPage from '../components/CenteredPage';

// Function to redirect user
const redirectUser = (history, user, show, permissions) => {
  // Redirect the user to the correct page
    if (permissions === "PRESENTER") {
      // Presenters go to the cue button
      history.push(`/cue/${show}-${user}`);
    } else if (permissions === "OPERATOR") {
      history.push(`/view/${show}`);
    } else {
      history.push(`/home/`);
    }
}


// Here we go!!
const Root = (props) => {
  // Set State
  const [ values, setValues ] = useState();
  const [ errorMessage, setErrorMessage ] = useState("");
 
  // Set up redux
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectLogin);
  const currentPermissions = useSelector(selectPermissions);

  const [ logOperation ] = useMutation(NEW_OPERATION_LOG);

  // Mutation for signing in
  const [ signIn, { data, loading } ] = useMutation(GET_INIT_DATA, {
    onCompleted: data => {
     
      // Update Redux State
      dispatch(login({ jwt: data.signIn.jwt, permissions: data.signIn.user.permissions}));

      // redirect the user
      redirectUser(
        props.history, 
        data.signIn.user._id, 
        data.signIn.user.shows[0]._id,
        data.signIn.user.permissions
      );
 
      // Log the sign in
      logOperation({ 
        variables: {
          user_id: data.signIn.user._id,
          info: data.signIn.user.name,
          operation: "SignIn",
          platform: platform.toString(),
        }
      });

    },
    onError: (err) => {
      // handle the error by logging it in the console
      // Not the best way...will change later
      setErrorMessage(`Error: ${err}`);
      console.log(err);
    }
  });
  

  const onChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  // Check to see if the user is already logged in...if so redirect to home
  useEffect( () => {
    // If we are already logged in and there is no data. 
    if (isLoggedIn && currentPermissions !== null && !loading) {
      // console.log("I think we are logged in");
      props.history.push("/home/");
    } 
  }, []);


  if (loading) {
    return (
      <CenteredPage>
        <p>Loading...</p>
      </CenteredPage>
    );
  };


  return (
    <CenteredPage>
      {errorMessage && <p>Incorrect Username or Password</p>}
      <Form onSubmit={ (e) => {
        e.preventDefault();
        signIn({
        variables: {
          ...values
        }
      })}}
      >
        <label htmlFor="username">Username:</label>
        <input
          required
          type="text"
          id="username"
          name="username"
          placeholder="username"
          onChange={onChange}
        />
        <label htmlFor="password">Password:</label>
        <input
          required
          type="password"
          id="password"
          name="password"
          placeholder="password"
          onChange={onChange}
        />
        <Button type="submit">Submit</Button>
      </Form>
    </CenteredPage>
  );
   
}

export default Root;
