import React from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery  } from '@apollo/client';
import { useSelector } from 'react-redux';
import {
  selectPermissions 
} from '../redux/loginSlice';

import { GET_SHOW } from '../gql/query'; 

import { exportCSVFile, firstToUpper } from '../utilities';

import { InLine } from '../components/DivUtility';
import CenteredPage from '../components/CenteredPage';
import LoadingError from '../components/LoadingError';
import Button, { ButtonWMargin } from '../components/Button';
import {
  Table,
  Tbody,
  Thead,
  Tr,
  Th,
  Td,
} from '../components/Table';


const Show = (props) => {
  const currentPermissions = useSelector(selectPermissions);
  const [ show ] = props.match.params.id.split("-");
  const history = useHistory();

  const { data, loading, error } = useQuery(GET_SHOW, { 
    variables: {
      showId: show
    }}
  );

  const handleAddUser = (e) => {
    e.preventDefault();
    history.push(`/adduser/${show}`);
  }

  const handleEditUser = (e, id) => {
    e.preventDefault();
    history.push(`/edituser/${show}-${id}`);
  }

  if (loading || error) {
    return(
      <LoadingError loading={loading} error={error} />
    )
  };

  return (
    <CenteredPage>
      <h2>{`Show: ${data.show.name}`}</h2>
      <InLine>
        <ButtonWMargin onClick={() => {
          props.history.push(`/view/${show}`);
        }}>
          View
        </ButtonWMargin>
        { currentPermissions === "ADMIN" ? (
          <React.Fragment>
            <ButtonWMargin onClick={() => {
              props.history.push(`/editshow/${show}`);
            }}> 
              Edit 
            </ButtonWMargin>
            <ButtonWMargin onClick={() => {
              exportCSVFile(
                ["Type", "ID", "Name", "Username", "Permissions", "Back Button", "View"], 
                data.show.users.map(x => x), data.show.name + "-users-" + data.show._id )
            }}>
                Export CSV
            </ButtonWMargin>
          </React.Fragment>
          ) : (
          <></>
        )}

      </InLine>
      <Table>
        <Thead>
          <Tr>
            <Th>Type</Th>
            <Th>Name</Th>
            <Th>Username</Th>
            <Th>Back Button</Th>
            <Th>View</Th>
            <Th>Edit</Th>
          </Tr>
        </Thead>
        <Tbody>
         {data.show.users.map( user => {
          return (
            <Tr key={user._id}>
              <Td>{firstToUpper(user.permissions)}</Td>
              <Td>{user.name}</Td>
              <Td>{user.username}</Td>
              <Td>
                {user.backButton ? (
                  <p>Yes</p>
                ) : (
                  <p>No</p>
                )}
              </Td>
              <Td>{firstToUpper(user.view)}</Td>
              <Td>
                <Button 
                  onClick={(e) => handleEditUser(e, user._id)}   
                >Edit</Button>
              </Td>
            </Tr>
          )
        })}
        </Tbody>
      </Table>
      <ButtonWMargin onClick={(e) => handleAddUser(e)}>Add User</ButtonWMargin>
    </CenteredPage>
  )
}

export default Show;
