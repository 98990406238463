import React, { useState } from 'react';
import { useMutation } from '@apollo/client';

import { NEW_USER } from '../gql/mutation'; 
import { GET_ADMINS } from '../gql/query';

import CenteredPage from '../components/CenteredPage';
import Form from '../components/Form';
import Button from '../components/Button';

const AddAdmin = (props)=> {
  const [ values, setValues ] = useState();

  
  const [ newUser ] = useMutation(NEW_USER, {
    refetchQueries: [{ query: GET_ADMINS }],
    awaitRefetchQueries: true,
    onError: err => {
      console.log(err);
    },
    onCompleted: ()=> {
      props.history.push(`/admins/`);
    },
  });



  const onChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  return (
    <CenteredPage>
      <h2>Add User</h2>
      <Form onSubmit={(e) => {
        e.preventDefault();
        newUser({
          variables: {
            name: values.name,
            password: values.password,
            permissions: "ADMIN",
          }
        });
      }}
      >
        <label htmlFor="name">Name:</label>
        <input
          required
          type="text"
          id="name"
          name="name"
          placeholder="name"
          onChange={onChange}
        />
        <label htmlFor="password">Password:</label>
        <input
          required
          type="text"
          id="password"
          name="password"
          placeholder="password"
          onChange={onChange}
        />
        <Button type="submit">Submit</Button>
      </Form>
    </CenteredPage>
  )
}

export default AddAdmin;


