import React from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { GET_ADMINS } from '../gql/query'; 

import CenteredPage from '../components/CenteredPage';
import LoadingError from '../components/LoadingError';
import Button, { ButtonWMargin } from '../components/Button';
import {
  Table,
  Tbody,
  Thead,
  Tr,
  Th,
  Td,
} from '../components/Table';



const Admins = (props) => {
  const history = useHistory();

  
  const { data, loading, error } = useQuery(GET_ADMINS);

  const handleEditUser = (e, id) => {
    e.preventDefault();
    history.push(`/editadmin/${id}`);
  }

  if (loading || error) {
    return(
      <LoadingError loading={loading} error={error} />
    )
  };

  return (
    <CenteredPage>
      <h2>Admins</h2>
      <Table>
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Edit</Th>
          </Tr>
        </Thead>
        <Tbody>
         {data.admins.map( user => {
          return (
            <Tr key={user._id}>
              <Td>{user.name}</Td>
              <Td>
                <Button 
                  onClick={(e) => handleEditUser(e, user._id)}   
                >Edit</Button>
              </Td>
            </Tr>
          )
        })}
        </Tbody>
      </Table>
      <ButtonWMargin onClick={(e) => props.history.push('/addadmin/')}>Add Admin</ButtonWMargin>
    </CenteredPage>
  )
}

export default Admins;
