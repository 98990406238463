import styled from 'styled-components';

export const Table = styled.table`
  padding: 5px;
  border-collapse: collapse;


`;

export const Tbody = styled.tbody`

`;

export const Thead = styled.thead`

`;

export const Tr = styled.tr`

`;

export const Th = styled.th`
  padding: 10px;
  padding-left: 40px;
  padding-right: 40px;
  border-bottom: 3px solid #222222;

`;

export const Td = styled.td`
  padding: 10px;
  padding-left: 40px;
  padding-right: 40px;

  border-bottom: 3px solid #222222;
`;


