import React from 'react';
import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import {
  selectPermissions 
} from '../redux/loginSlice';

import { ME } from '../gql/query';
import { ButtonWMargin } from '../components/Button';
import CenteredPage from '../components/CenteredPage';
import Ul from '../components/Ul';
import Li from '../components/Li';
import ButtonAsLink from '../components/ButtonAsLink';
import { FlexRow } from '../components/DivUtility';


const Home = (props) => {
  const currentPermissions = useSelector(selectPermissions);
  const { data, loading, error } = useQuery(ME, {
    fetchPolicy: "network-only"
  });


  const handleClick = (e, user, show) => {
    e.preventDefault();
    if (user.permissions === "PRESENTER") {
      // Presenters go to the cue button
      props.history.push(`/cue/${show._id}-${user._id}`);
    } else if (user.permissions === "OPERATOR") {
      props.history.push(`/show/${show._id}`);
    } else {
      props.history.push(`/show/${show._id}`);
    }
    
  }

  if (loading) {
    return (
      <CenteredPage>
        <h1>User</h1>
      </CenteredPage>
    )
  }

  if (error) {
    return(
      <CenteredPage>
        <p>Error...</p>
      </CenteredPage>
    )
  };

  return (
    <CenteredPage>
      <h2>{`User: ${data.me.name}`}</h2>
      <Ul>
        <Li>
          <h3>
            Active Shows
          </h3>
        </Li>
        {data.me.shows.map( show => {
          return (
            <Li key={show._id}>
                <ButtonAsLink onClick={(e) => handleClick(e, data.me, show)}>
                    {show.name}</ButtonAsLink>
            </Li>
          )
        })}
      </Ul>
      { currentPermissions === "ADMIN" ? (
        <FlexRow>
          <ButtonWMargin onClick={() => {
            props.history.push(`/addshow/`);
          }}> 
            Add Show
          </ButtonWMargin>
          <ButtonWMargin onClick={() => {
            props.history.push(`/admins/`);
          }}> 
            Admins 
          </ButtonWMargin>
          <ButtonWMargin onClick={() => {
            props.history.push('/latency/');
          }}>
            Latency
          </ButtonWMargin>
        </FlexRow>
      ) : (
        <></>
      )}
    </CenteredPage>
  );
}

export default Home;
