import styled from 'styled-components';


export const ExtraMargin = styled.div`
  margin: 1em;
`;

export const ExtraPadding = styled.div`
  padding-bottom: 1em;
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

export const InLine = styled.div`
  display: inline-block;
  margin: 1em;
`;
