import React, { useEffect } from 'react';
import { 
  ApolloClient, 
  ApolloProvider, 
  InMemoryCache,
  createHttpLink,
} from '@apollo/client';

import { onError } from "@apollo/client/link/error";

import { setContext } from 'apollo-link-context';

import { logout } from './redux/loginSlice';
import store from './redux/store';

import Pages from './pages';
import GlobalStyles from './GlobalStyles';

// configure our URI and cache
const uri = process.env.REACT_APP_DB_API_URI;
const cache = new InMemoryCache({
  typePolicies: {
    // Not sure exacty if this is the best way to handle the:
    //    Cache data may be lost when replacing the users field of a Show object.
    //    https://github.com/apollographql/apollo-client/pull/6372
    Show: {
      fields: {
        users: {
          merge: false
        }
      }
    },
    Query: {
      fields: {
        admins: {
          merge: false
        }
      }
    },
    User: {
      // Tell apollo that _id is the field to index by
      keyFields: ["_id"],
      fields: {
        shows: {
          merge: false
        }
      }
    },
  }
});
const httpLink = createHttpLink({ uri });

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      switch (err.extensions.code) {
        case 'UNAUTHENTICATED': 
          // If the error code is unauthenticated
          
          // Do we need to clear the apollo cache?
          store.dispatch(logout());
          break;
        
        default: 
          // Otherwise just spit out the error into the console.
          console.log(`GraphQL Error: Message: ${err.message}, Location: ${err.location}, Path: ${err.path}`)
          console.log(err);

        break
      }
    }
  }
});


const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: localStorage.getItem('token') || ''
    }
  };
});

const client = new ApolloClient({
  link: errorLink.concat(authLink.concat(httpLink)),
  cache,
  connectToDevTools: true,
});


const App = () => {

  useEffect( () => {
    // prevent the right click
    document.addEventListener("contextmenu", e => {e.preventDefault();});
    document.oncontextmenu = (e) => {
      e.preventDefault();
    };
  });

  return (
    <ApolloProvider client={client}>
      <GlobalStyles />
      <Pages />
    </ApolloProvider>
  );
}

export default App;
