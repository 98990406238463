import { gql } from '@apollo/client';

export const GET_SHOW = gql`
  query getShow ($showId: ID, $showName: String) {
    show(id: $showId, name: $showName) {
      _id
      name
      users {
        _id
        name
        username
        permissions
        backButton
        view
      }
    }
  }
`;

export const GET_SHOWS = gql`
  query shows {
    shows {
      _id
      name
      users {
        _id
        name
        username
        permissions
      }
    }
  }
`;



export const GET_USER_AND_SHOW = gql`
  query getData ($userId: ID, $showId: ID) {
    user(id: $userId) {
      _id
      name
      backButton
      view
    }
    show(id: $showId) {
      _id
      name
    }
  }
`;


export const GET_USER = gql`
  query getUser ($id: ID) {
    user(id: $id) {
      _id
      name
      permissions
      view
    }
  }
`;
      


export const ME = gql`
  query me {
    me {
      _id
      name
      username
      permissions
      shows {
        _id
        name
        }
    }
  }
`;

export const GET_ADMINS = gql`
  query admins {
    admins {
      _id
      name
      permissions
      shows {
        _id
      }
    }
  }
`;


