import styled from 'styled-components';

const Button = styled.button`
  background-color: #444444;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.50);
  border: none;
  color: #fff;
  padding: 1em;
  border-radius: 1px;

  :hover {
    background-color: #333333;
  }

  :active {
    background: #222222;
  }
`;

export const ButtonWMargin = styled(Button)`
  margin: 1em;
`;

export default Button;
  
