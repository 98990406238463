import React from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  selectLogin,
  selectPermissions
} from '../redux/loginSlice';

import Layout from '../components/Layout';

import Root from './Root';
import Cue from './Cue';
import Home from './Home';
import View from './View';
import Show from './Show';
import Admins from './Admins';
import AddUser from './AddUser';
import AddAdmin from './AddAdmin';
import EditAdmin from './EditAdmin';
import AddShow from './AddShow';
import EditUser from './EditUser';
import EditShow from './EditShow';
import PrivacyPolicy from './PrivacyPolicy';
import Latency from './Latency';


const Pages = () => {


  return (
    <Router>
      <Layout>
        <Route exact path='/' component={Root} />
        <PrivateRoute 
          path='/cue/:id' 
          component={Cue} 
          permissions={["PRESENTER"]}
        />
        <PrivateRoute 
          path='/home/' 
          component={Home} 
          permissions={["OPERATOR", "PRESENTER"]}
        />
        <PrivateRoute 
          path='/view/:id' 
          component={View} 
          permissions={["OPERATOR"]}
        />
        <PrivateRoute 
          path='/admins/' 
          component={Admins} 
          permissions={["OPERATOR"]}
        />
        <PrivateRoute 
          path='/addadmin/' 
          component={AddAdmin} 
          permissions={[]}
        />
        <PrivateRoute 
          path='/editadmin/:id' 
          component={EditAdmin} 
          permissions={[]}
        />
        <PrivateRoute 
          path='/latency/' 
          component={Latency} 
          permissions={["OPERATOR"]}
        />
        <PrivateRoute 
          path='/adduser/:id' 
          component={AddUser} 
          permissions={["OPERATOR"]}
        />
        <PrivateRoute 
          path='/addshow/' 
          component={AddShow} 
          permissions={["OPERATOR"]}
        />

        <PrivateRoute 
          path='/edituser/:id' 
          component={EditUser} 
          permissions={["OPERATOR"]}
        />
        <PrivateRoute 
          path='/editshow/:id' 
          component={EditShow} 
          permissions={["OPERATOR"]}
        />

        <PrivateRoute 
          path='/show/:id' 
          component={Show} 
          permissions={["OPERATOR"]}
        />
        <Route path='/privacypolicy' component={PrivacyPolicy} />
      </Layout>
    </Router>
  )
};

// ADMIN is always implied, but "OPERATOR" and "PRESENTER" always need to be defined...as of now it throws a nasty error if props.permissions isn't defined...need to work on that.
const PrivateRoute = ({ component: Component, ...rest }) => {
  const isLoggedIn = useSelector(selectLogin);
  const permissions = useSelector(selectPermissions);
  return (
    <React.Fragment>
      <Route
        {...rest}
        render={props =>
            (isLoggedIn === true && (permissions === "ADMIN" || rest.permissions.includes(permissions) || !rest.permissions)) ? (
            <React.Fragment>
              <Component {...props} />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Redirect
                to={{
                  pathname: '/',
                  state: { from : props.location }
                }}
              />
            </React.Fragment>
          )
        }
      />
    </React.Fragment>
  );
};

export default Pages
