import React  from 'react';
import styled from 'styled-components';


const GreenTriangleOn= styled.div`
  width: 0;
  height: 0;
  border-left: 80px solid transparent;
  border-right: 80px solid transparent;
  border-bottom: 160px solid #00ff00;
  transform: rotate(90deg);
  margin: auto;
`;

const GreenTriangleOff = styled.div`
  width: 0;
  height: 0;
  border-left: 80px solid transparent;
  border-right: 80px solid transparent;
  border-bottom: 160px solid #808080;
  transform: rotate(90deg);
  margin: auto; 
`;

const GreenTriangle = (props) => {

  return (
    <React.Fragment>
      { (props.on) ? (
        <GreenTriangleOn />
      ) : (
        <GreenTriangleOff />
      )
      }
    </React.Fragment>
  );
}

export default GreenTriangle;
      


