import React, { useState }  from 'react';
import styled from 'styled-components';

import red from "../../img/red.png";
import red_pressed from "../../img/red_pressed.png";

const Button = styled.button`
  padding: 0;
  margin: 0;
  background: inherit;
  border-style: none;
`;

const Img = styled.img`
  height: 30%;
  width: 30%;
  padding: 0;
  margin: 0;
`;

const ButtonBack = (props) => {

  const [pressed, setPressed] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    props.onClick();
    setPressed(true);
    setTimeout(() => setPressed(false), 1000);
  }

  return (
    <Button>
      <Img
        src={! pressed ? red : red_pressed } 
        alt="green" 
        onClick={handleClick} /> 
    </Button>
  ); 
};

export default ButtonBack;


