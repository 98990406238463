import React, { useState, useEffect }  from 'react';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';

import { GET_SHOW } from '../gql/query';

import socketConnect from "../socket-connect";
import { useDispatch } from 'react-redux';
import { logout } from '../redux/loginSlice';

import Form , {FormRow} from '../components/Form';
import Ul from '../components/Ul';
import Li from '../components/Li';
import CenteredPage from '../components/CenteredPage';
import ButtonAsLink from '../components/ButtonAsLink';
import { ExtraMargin } from '../components/DivUtility';
import GreenTriangle from '../components/CueViewComponents/GreenTriangle';
import RedTriangle from '../components/CueViewComponents/RedTriangle';
import LoadingError from '../components/LoadingError';
import ButtonWMargin from '../components/Button';
import {
  Table,
  Tbody,
  Thead,
  Tr,
  Th,
  Td,
} from '../components/Table';

import {Howl} from 'howler';

const FlexDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Split = styled.div`
  flex: flex-grow;
`;

// Initialize variables
let socket;
let sounds;

// React component
const View = (props) => {
  const [ show ] = props.match.params.id.split("-");

  const [ go, setGo] = useState('');
  const [ back, setBack] = useState('');
  const [ greenTriangle, setGreenTriangle ] = useState(false);
  const [ redTriangle, setRedTriangle ] = useState(false);
  const [connected, setConnected] = useState(false);
  const [ multiView, setMultiView ] = useState(false);
  const [ selectedPresenter, setSelectedPresenter ] = useState({
    id: null, 
    name: null,
  });
  const [ presenterName, setPresenterName ] = useState('');
  const [ webSocketCompatable, setWebsocketCompatable ] = useState(true);
  const [ audioStatus, setAudioStatus ] = useState(false);
  const [ history, setHistory ] = useState([]);

  const { data, loading, error } = useQuery(GET_SHOW, { 
    variables: {
      showId: show
    },
    pollInterval: 5000
  });

  // initialize the Redux Hook
  const dispatch = useDispatch();

  // Create a datetime object
  const datetime = new Date();

  const chunkArray = (array, size) => {
    const userChunks = [];
    let index = 0;
    while (index < array.length) {
      userChunks.push(array.slice(index, index+size));
      index += size;
    }
    return userChunks;
  }


  // Set up the sounds and sprites
  useEffect( () => {
    sounds = new Howl({
      src: ['/sounds.webm', '/sounds.mp3'],
      sprite: {
        go: [0, 142.35827664399093],
        back: [2000, 151]
      },
    });

    // Mute the audio until it is called for.
    sounds.mute(true);
  },[]);

  // Handle all the socket connect
  useEffect( () => {
    socket = socketConnect();

    // Set the time out before the no Websockets message displays
    setTimeout( () => {
      setWebsocketCompatable(false)
    }, 7000 );

    // return the disconnect to prevent memory leak
    return () => {
      socket.disconnect();
    }
  }, []);

  // All receiveing socket logic
  useEffect(() => {
    socket.on('connect', () => {
      // Send over which show/room to use
      socket.binary(false).emit("info", show);
      setConnected(true);
      console.log(`connnected: ${socket.id}`);
    });
    
    socket.on('reconnecting', () => {
      setConnected(false);
    });

    socket.on('unauthorized', (error ) => {
      console.log(error);
      if (error.data.type === 'UnauthorizedError' || error.data.code === 'invalid_token') {
        console.log('User Token has expired');
        dispatch(logout);
      }
    });

    socket.on("go", payload => {
      setGo(payload);
    });

    socket.on("back", payload => {
      setBack(payload);
    });

  }, [dispatch, show]);


  const idToName = (users, id) => {
    for (const i in users) {
      if (users[i]._id === id) {
        return users[i].name;
      }
    }
  }

  // handle the cue history
  let currentHistory = [];
  const addHistory = (name) => {
    currentHistory = history;
    currentHistory.push({name: name, timestamp: datetime.toISOString()});

    if (currentHistory.length > 5) {
      currentHistory.shift();
    }
    setHistory(currentHistory);
  }

  
   // React Hook to handle all of the GO logic
  // Re-renders when there is a change to the state go.
  useEffect( () => {

    // Check to make sure we are not still loading the db data
    if (!(loading || error)) {
      let name = idToName(data.show.users, go);
      // if multiview is engaged and there is a chnage to go
      if (multiView && !!go) {
        // If the selected presenter is the one clicking
        if (selectedPresenter.id === go) {
          setGreenTriangle(true);
          addHistory(name);
          sounds.play('go');
          setTimeout(() => {
            setGo('');
            setGreenTriangle(false);
          }, 1000);
        // if the selected presenter is not clicking
        } else {
          setPresenterName(name);
          addHistory(name);
          setTimeout(() => {
            setGo('');
            setPresenterName('');
          }, 1000);
        }
        // If multiview is not engaged
      } else if (!multiView && !!go) {
        setPresenterName(name);
        addHistory(name);
        sounds.play('go');
        setGreenTriangle(true);
        setTimeout(() => {
          setGo('');
          setGreenTriangle(false);
          setPresenterName('');
        }, 1000);
      } else {
        setGo('');
      }
    }

  },[multiView, selectedPresenter, data, loading, error, go, audioStatus, history ]);
  // I don't want to add addHistory nor datetime as a dependnecy because I don't want 
  // those to cause a rerender....maybe you should create an import which would be static.

  // React Hook to handle all of the Back logic
  useEffect( () => {


    if (!(loading || error)) {
      // define name 
      let name = idToName(data.show.users, back);
      if (multiView && !!back) {
        if (selectedPresenter.id === back) {
          setRedTriangle(true);
          addHistory(name);
          sounds.play('back');
          setTimeout(() => {
            setBack('');
            setRedTriangle(false);
          }, 1000);
        } else {
          setPresenterName(name);
          addHistory(name);
          setTimeout(() => {
            setBack('');
            setPresenterName('');
          }, 1000);
        }
      } else if (!multiView && !!back) {
        setPresenterName(name);
        addHistory(name);
        setRedTriangle(true);
        sounds.play('back');
        setTimeout(() => {
          setBack('');
          setRedTriangle(false);
          setPresenterName('');
        }, 1000);
      } else {
        setBack('');
      }
    } 
  }, [multiView, selectedPresenter, data, loading, error, back, audioStatus, history]);


  if (loading || error) {
    return(
      <LoadingError loading={loading} error={error} />
    )
  };

  if (!connected) {
    return(
      <CenteredPage>
        <p>Connecting...</p>
      { ! webSocketCompatable ? (
          <React.Fragment>
            <p>It looks like you've been waiting for a while.</p>
            <p>Your internet connection seems to not support WebSockets which are required for vCue.</p>
            <p>Please make sure all VPN's and proxies are off.</p>
            <p>If you are on a mobile device, try turning wifi off and cellular data on.</p>
          </React.Fragment>
        ) : (
          <></>
        )}

      </CenteredPage>
    )
  };

  // handle the enable audio button
  const enableAudio = (e)=> {
    // e.preventDefault();
    setAudioStatus(!audioStatus);
    // Wait 170ms before unmuting the audio to ensure that the buffered clicks aren't heard
    setTimeout(() => {
      sounds.mute(audioStatus)
    }, 170);
  }

  return (
    <CenteredPage>
      <h2>{`${data.show.name}`}</h2>
      {/*
      { ! audioStatus ? (
        <ButtonWMargin
          onClick={enableAudio}
        >
          Enable Audio
        </ButtonWMargin>
      ) : (
        <ButtonWMargin
          onClick={enableAudio}
        >
          Disable Audio
        </ButtonWMargin>

      )}
      */}
      { multiView ? (
        <FlexDiv>
          { chunkArray(data.show.users, 10).map(userSet => (
          <Split key={Math.random()}>
          <ExtraMargin>
            <Ul>
              {userSet.map(user => (
                <Li key={user._id}>
                  <ButtonAsLink onClick={() => {
                    setSelectedPresenter({ id: user._id, name: user.name });
                  }}
                  >{user.name}</ButtonAsLink>
                </Li>
              ))}
            </Ul>
          </ExtraMargin>
          </Split>
          ))}
          <Split>
          <ExtraMargin>
            <CenteredPage>
              <h3>{selectedPresenter.name || "Please Select Presenter" }</h3>
              <GreenTriangle on={greenTriangle} />
              <RedTriangle on={redTriangle} />
              <h3>{`Testing: \n${presenterName}`}</h3>
            </CenteredPage>
          </ExtraMargin>
          </Split>
        </FlexDiv>
             ) : (
        <CenteredPage>
          <GreenTriangle on={go} />
          <RedTriangle on={back} />
          <h3>{`Presenter: ${presenterName || ''}`}</h3>
        </CenteredPage>
      )}
      <h3>History:</h3>
      <Table>
        <Thead>
          <Tr>
            <Th>Timestamp</Th>
            <Th>Name</Th>
          </Tr>
        </Thead>
        <Tbody>
          {history.slice(0).reverse().map(item => (
            <Tr key={Math.random()}>
              <Td>{item.timestamp}</Td>
              <Td>{item.name}</Td>
            </Tr>
          ))}

        </Tbody>
      </Table>
      <Form>
        <label htmlFor="multiview">Presenter List</label>
        <input
            type="checkbox"
            id="multiview"
            name="multiview"
            value={false}
            onChange={() => setMultiView(!multiView)}
        />
        <label htmlFor="audioEnable">Enable Audio</label>
        <input
          type="checkbox"
          id="enableAudio"
          name="enableAudio"
          value={false}
          onChange={enableAudio}
        />
      </Form>
    </CenteredPage>
  );
}

export default View;
