import React, { useState } from 'react';
import {
  DDWrapper,
  DDHeader,
  DDHeaderTitle,
  DDList,
  DDListItem,
} from './DropdownStyled';
import {
  Check,
  ArrowUp,
  ArrowDown,
} from './Icons';

const Dropdown = (props) => {
  const [ isOpen, setIsOpen ] = useState(false);
  //const [ value, setValue ] = useState("Hey");
  const [ options, setOptions ] = useState(props.options.map(op => {
    return {
      ...op,
      selected: false,
    }
  }));
  const [ title, setTitle ] = useState(props.title);

  const toggleList = () => {
    // console.log("toggleList ", isOpen);

    if (!isOpen) {
      setTimeout(() => {
        window.addEventListener('click', close, {
              once: true,
            });
      }, 0);
    } else {
      setTimeout(() => {
        window.removeEventListener('click', close, {
              once: true,
            });
      }, 0);
    }
    setIsOpen(!isOpen);

  }

  const selectItem = (e, item) => {
    // console.log("Hey! Select an Item!");
    // e.stopPropagation();
    setOptions(options.map(option => {
      if (option._id === item._id) {
        // setTitle(`Control Room: ${item.name}`);
        setTitle(item.name);
        option.selected = true;
        // setValue(item._id);
        props.onChange({target: { 
          value: item._id,
          name: props.name,
        }});
        return option;
      } else {
        option.selected = false;
        return option;
      }
    }))
  }


  
  const close = () => {
    setIsOpen(false);
  }

 
  return (
    <React.Fragment>
      {/*console.log(isOpen)*/}
      <DDWrapper>
        <DDHeader
          onClick={toggleList}
        >
          <DDHeaderTitle>{title}</DDHeaderTitle>
          {isOpen ? (
            <ArrowUp/>
          ) : ( 
            <ArrowDown/>
          )}
          {'  '}
        </DDHeader>
        {isOpen && (
          <DDList>
            {options.map((item) => (
              <DDListItem
                key={item._id}
                onClick={(e) => selectItem(e, item)}
              >
                {item.name}
                {item.selected && (<Check/>)}
              </DDListItem>
            ))}
          </DDList>
        )}
      </DDWrapper>
    </React.Fragment>
  )
}

export default Dropdown;

