import io from "socket.io-client";

const ENDPOINT = process.env.REACT_APP_SOCKET_API_URI;


// Write a function to run the conncetion sequence
const socketConnect = () => {
  // console.log(ENDPOINT);
  return io(ENDPOINT, {
    reconnectionDelay: 500,
    reconnectionDelayMax: 1000,
    query: `token=${localStorage.getItem('token') || ''}`,
    transports: ['websocket'],
    extraHeaders: { Authorization: `Bearer ${localStorage.getItem('token') || ''}`},
  });
};

export default socketConnect;

