
export const firstToUpper = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

const objToCSVString= (objArray) => {
  // Check to make sure the type is a object then parse it with JSON
  let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
  let CSVstr = '';

  for (let i = 0; i < array.length; i++) {
    let line = '';
    for (let index in array[i]) {
      if (line !== '') {
        line += ',';
      }
      line += array[i][index];
    }
    CSVstr += line + '\n\r';
  }
  return CSVstr;
}

export const exportCSVFile = (headers, items, fileTitle) => {
  // shift in the headers at the top
  if (headers) {
      items.unshift(headers);
  }

  // convert to csv
  let csv = objToCSVString(items);
  // Create the file name
  const exportFilename = fileTitle + '.csv' || 'export.csv';
  // Build the blob
  let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  // handle IE 10+
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, exportFilename);
  } else {
    let link = document.createElement("a");
    // feature detection for browsers that support HTML5 download
    if (link.download !== undefined) {
      let url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", exportFilename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);  // Create a link element
      link.click();                     // Click the link element
      document.body.removeChild(link);  // remove the link element
    }
  }
}

