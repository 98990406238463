import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';

import { GET_SHOW, GET_USER } from '../gql/query';
import { UPDATE_USER, REMOVE_USER_BY_ID } from '../gql/mutation';

import CenteredPage from '../components/CenteredPage';
import Form from '../components/Form';
import { ButtonWMargin } from '../components/Button';
import LoadingError from '../components/LoadingError';
import Dropdown from '../components/Drowpdown';
import { ExtraPadding, InLine } from '../components/DivUtility';

const userTypes = [ { _id: "PRESENTER" , name: "Presenter"}, { _id: "OPERATOR", name: "Operator"}  ];
const viewTypes = [
  { _id: "NORMAL", name: "Normal" },
  { _id: "SIMPLE", name: "Simple" },
  { _id: "EXTERNAL", name: "External Cue" },
];


const EditUser = (props)=> {
  // Ge the showId and User Id from the url
  const [ showId, userId ] = props.match.params.id.split("-");
  const [ errorMessage, setErrorMesssage ] = useState("");

  // get the user info
  const { data, loading, error } = useQuery(GET_USER, {
    variables: { id: userId}
  });

  // mutation for updating the info
  const [ updateUser ] = useMutation(UPDATE_USER, {
    refetchQueries: [{ query: GET_SHOW, variables: { showId: showId }}],
    awaitRefetchQueries: true,
    onError: err => {
      console.log(err);
      if (err.toString().includes("E1100")) {
        setErrorMesssage(`Error Updating User: Name must be unique`);
      } else {
        setErrorMesssage(`Error Updating User`);
      }
    },
    onCompleted: ()=> {
      props.history.goBack();
    },
  }); 

  // mutation for deleting a user
  const [ removeUserById ] = useMutation(REMOVE_USER_BY_ID, {
    refetchQueries: [{ query: GET_SHOW, variables: { showId: showId } }],
    awaitRefetchQueries: true,
    onError: err => {
      console.log(err);
      if (err.toString().includes("Cannot Delete Yourself")) {
        setErrorMesssage(`Error Deleting User: Cannot Delete Yourself`);
      } else {
        setErrorMesssage(`Error Deleting User`);
      }
    },
    onCompleted: (data)=> {
      props.history.goBack();

    },
  });


  const [ backButton, setBackButton ] = useState(false);
  // state holding form variables
  const [ values, setValues ] = useState({
    name: '',
    permissions: '',
    changepassword: false,
    password: '',
    type: '',
    view: '',
  });

  // State for displaying or hiding the change password box
  const [ changePassword, setChangePassword ] = useState(false);

  // shove the values from the database into sate
  useEffect(() => {
    if (!(loading || error)) {
      setValues({ 
        name: data.user.name,
        permissions: data.user.permissions,
        view: data.user.view,
        password: '',
      })
    }
  }, [data, loading, error]);


  // Delte the user
  const handleRemoveUser = (e) => {
    e.preventDefault();
    removeUserById({
      variables: {
        id: userId 
      }
    });
  };

  // On form change map the values to the keys
  const onChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
    console.log(values);
  };


  // handle loading and error for fetching data
  if (loading || error) {
    return(
      <LoadingError loading={loading} error={error} />
    )
  };

   
  return (
    <CenteredPage>
      <h2>Edit User</h2>
      <h3>{errorMessage}</h3>
      <Form
        onSubmit={(e) => {
          e.preventDefault();

          updateUser({
            variables: {
              id: userId,
              name: values.name,
              password: values.password,
              permissions: values.permissions,
              backButton: !!backButton,
              view: values.view,
            }
          });
        }}
      >
        <label htmlFor="name">Name:</label>
        <input 
          type="text"
          id="name"
          name="name"
          value={values.name}
          onChange={onChange}
        />
        <ExtraPadding>
          <h3>Select User Type</h3>
          <Dropdown
            title={data.user.permissions }
            options={userTypes}
            name="permissions"
            onChange={onChange}
          />
        </ExtraPadding>
        { values.permissions === "PRESENTER" ? (
          <React.Fragment>
            <ExtraPadding>
              <h3>Select User View</h3>
              <Dropdown
                title={data.user.view}
                options={viewTypes}
                name="view"
                onChange={onChange}
              />
            </ExtraPadding>
            <label htmlFor="backButton">Back Button?</label>
            <input
              type="checkbox"
              id="backButton"
              name="backButton"
              value={false}
              onChange={() => setBackButton(!backButton)}
            />
          </React.Fragment>
        ) : (
          <></>
        )}
        <label htmlFor="changepassword">Change Password?</label>
        <input
            type="checkbox"
            id="changepassword"
            name="changepassword"
            value={false}
            onChange={() => setChangePassword(!changePassword)}
          />
        { changePassword ? (
          <React.Fragment>
            <label htmlFor="password">New Password:</label>
            <input 
              type="text"
              id="password"
              name="password"
              value={values.password}
              onChange={onChange}
            />
          </React.Fragment>
        ) : ( <></>)
        }
        <InLine>
          <ButtonWMargin onClick={props.history.goBack} >Cancel</ButtonWMargin>
          <ButtonWMargin type="submit">Submit</ButtonWMargin>
        </InLine>
      </Form>
      <ButtonWMargin onClick={(e) => handleRemoveUser(e)}>Delete User</ButtonWMargin>
    </CenteredPage>
  )
}

export default EditUser;


