import styled from 'styled-components';


const Li = styled.li`
  display: block;
  padding: 10px;
  color: #fff;
  border-bottom: 3px solid #222222;
  display: flex;
  justify-content: center;

  :nth-last-child(1) {
    border-bottom: none;
  }
`;

export default Li;
