import React from 'react';
import CenteredPage from '../components/CenteredPage';

const LoadingError = (props) => {
  return (
    <React.Fragment>
      {props.loading ? (
        <CenteredPage>
          <p>Loading...</p>
        </CenteredPage>
      ) : (<></>)}
      {props.error ? (
        <CenteredPage>
          <p>Error...</p>
        </CenteredPage>
      ) : (<></>)}

    </React.Fragment>

  )


}

export default LoadingError;
