import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';

import { GET_SHOW, ME } from '../gql/query';
import { UPDATE_SHOW } from '../gql/mutation';

import CenteredPage from '../components/CenteredPage';
import Form from '../components/Form';
import  { ButtonWMargin } from '../components/Button';
import LoadingError from '../components/LoadingError';
import { InLine } from '../components/DivUtility';

import { REMOVE_SHOW_BY_ID } from '../gql/mutation';


const EditShow = (props)=> {
  // Get the showId from the url
  const [ showId ] = props.match.params.id.split("-");

  // get the show info
  const { data, loading, error } = useQuery(GET_SHOW, {
    variables: { showId: showId }
  });

  // mutation for deleting a show
  const [ removeShowById ] = useMutation(REMOVE_SHOW_BY_ID, {
    refetchQueries: [{ query: ME}],
    awaitRefetchQueries: true,
    onError: err => {
      console.log(err);
    },
    onCompleted: (data)=> {
      props.history.push(`/home/`);

    },
  });

  const [ updateShow ] = useMutation(UPDATE_SHOW, {
    refetchQueries: [{ query: ME}, { query: GET_SHOW, variables: { showId: showId }}],
    awaitRefetchQueries: true,
    onError: err => {
      console.log(err);
    },
    onCompleted: (data)=> {
      props.history.push(`/show/${showId}`);

    },
  });


  // state holding form variables
  const [ values, setValues ] = useState({
    name: '',
  });


  // shove the values from the database into sate
  useEffect(() => {
    if (!(loading || error)) {
      setValues({ 
        name: data.show.name,
      })
    }
  }, [data, loading, error]);


  // Delte the user
  const handleRemoveShow = (e) => {
    e.preventDefault();
    removeShowById({
      variables: {
        id: showId
      }
    });
  };

  // On form change map the values to the keys
  const onChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };


  // handle loading and error for fetching data
  if (loading || error) {
    return(
      <LoadingError loading={loading} error={error} />
    )
  };

   
  return (
    <CenteredPage>
      <h2>Edit Show</h2>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          updateShow({
            variables: {
              id: showId,
              name: values.name
            }
          });
        }}
      >
        <label htmlFor="name">Name:</label>
        <input 
          type="text"
          id="name"
          name="name"
          value={values.name}
          onChange={onChange}
        />
        <InLine>
          <ButtonWMargin onClick={props.history.goBack} >Cancel</ButtonWMargin>
          <ButtonWMargin type="submit">Submit</ButtonWMargin>
        </InLine>
      </Form>
      <ButtonWMargin onClick={(e) => handleRemoveShow(e)}>Delete Show</ButtonWMargin>
    </CenteredPage>
  )
}

export default EditShow;

