import React, { useEffect, useState }  from 'react';
import NoSleep from 'nosleep.js';
import platform from 'platform';

import { useDispatch } from 'react-redux';
import { logout } from '../redux/loginSlice';
import { useQuery, useMutation } from '@apollo/client';

import { GET_USER_AND_SHOW } from '../gql/query';
import { NEW_OPERATION_LOG } from '../gql/mutation';

import CenteredPage from '../components/CenteredPage';
import LoadingError from '../components/LoadingError';
import ButtonGo from '../components/CueViewComponents/ButtonGo';
import ButtonBack from '../components/CueViewComponents/ButtonBack';
import BigGreen from '../components/CueViewComponents/BigGreen';
import BigRed from '../components/CueViewComponents/BigRed';
import GreenTriangle from '../components/CueViewComponents/GreenTriangle';
import RedTriangle from '../components/CueViewComponents/RedTriangle';

import socketConnect from '../socket-connect';

let socket;

const Cue = (props) => {
  const [ hasFocus, setHasFocus ] = useState(true);
  const [ goTriangle, setGoTriangle ] = useState('');
  const [ backTriangle, setBackTriangle ] = useState('');
  const [ connected, setConnected ] = useState(false);
  const [ webSocketCompatable, setWebsocketCompatable ] = useState(true);
  const [ noSleepEnabled, setNoSleepEnabled ] = useState(false);

  // const [ latencies, setLatencies ] = useState([]);

  const [ show, user ] = props.match.params.id.split("-");

  const dispatch = useDispatch();

  const { data, loading, error } = useQuery(GET_USER_AND_SHOW, { 
    variables: {
      userId: user, 
      showId: show
    },
    fetchPolicy: "network-only"
  });

  const [ logOperation ] = useMutation(NEW_OPERATION_LOG);

  const noSleep = new NoSleep();

  // Function to keydown to add arrow functionality
  const handleKeydown = (e) => {
    // Right array
    if (e.keyCode === 39) {
      go();
    
    // Left arrow. Also check to make sure the back button is enabled
    } else if (e.keyCode === 37 && data.user.backButton) {
      back();
    }
  };


  
  // Add window hooks for on focus and blur
  useEffect(() => {
    // Set the focus to true if the window is in focus
    window.onfocus = () => {
      setHasFocus(true);
    };
    
    // blur is how to tell if the window is NOT focused
    window.onblur = () => {
      setHasFocus(false);
    };
  },[]);

  // Here's the react hook. Note that the handleKeydown isn't in the dependency list
  // ...otherwise it would need to be wraped in it's own event hook or 
  // imported (prefered)...and I'm a bit lazy. 
  useEffect(() => {
    window.addEventListener('keydown', handleKeydown);

    return () => {
      window.removeEventListener('keydown', handleKeydown);
    }

  // The data dependency reloads the eventListener with the correct user data.
  // Becuase it gets thrown on the event stack before the data has been returned
  // from the server and hense has no data.
  }, [data]); 

  // Hook for connection
  useEffect(() => {
    // connect to the socket
    socket = socketConnect();

    // Set the time out before the no Websockets message displays
    setTimeout( () => {
      setWebsocketCompatable(false)
    }, 7000 );


    // On end, disconnted from socket.
    // This prevents a memory leak
    return () => {
      logOperation({
        variables: {
          user_id: user,
          info: socket.id,
          operation: "WSS-Disconnect",
          platform: platform.toString(),
        }
      });
      socket.disconnect();
    }
  }, []);

  // Hook for all socket "on" 
  useEffect(() => {
    socket.on('connect', () => {
      // Send over which room to use
      socket.binary(false).emit("info", show);
      setConnected(true);
      logOperation({
        variables: {
          user_id: user,
          info: socket.id,
          operation: "WSS-Connect",
          platform: platform.toString(),
        }
      });
      console.log(`connnected: ${socket.id}`);
    });

    socket.on('error', (error) => {
      console.log(error)
      logOperation({
        variables: {
          user_id: user,
          info: socket.id,
          operation: "WSS-Error",
          platform: platform.toString(),
        }
      });
    });
    
    socket.on('reconnecting', () => {
      setConnected(false);
      logOperation({
        variables: {
          user_id: user,
          info: socket.id,
          operation: "WSS-Reconnecting",
          platform: platform.toString(),
        }
      });
    });

    socket.on('unauthorized', (error ) => {
      console.log(error);
      if (error.data.type === 'UnauthorizedError' || error.data.code === 'invalid_token') {
        console.log('User Token has expired');
        dispatch(logout);
      }
    });

    /* 
    socket.on('pong', ms => {
      setLatencies(latencies.push(ms));
      let average = (latencies.reduce( (a,b) => (a+b), 0))/latencies.length;
      console.log(`average = ${average}, last = ${ms}, pings = ${latencies.length}`);
    });
    */
    
    
  },[dispatch, show]);

  const go = (e) => {
    if (!noSleepEnabled) {
      noSleep.enable();
      setNoSleepEnabled(true);
      console.log("Enabling no sleep");
    }
    socket.binary(false).emit("go", user);
    setGoTriangle(true);
    setTimeout(() => {
      setGoTriangle(false);
    }, 1000);
  }

  const back = (e) => {
    socket.binary(false).emit("back", user);
    setBackTriangle(true);
    setTimeout(() => {
      setBackTriangle(false);
    }, 1000);

  }

  if (loading || error) {
    return(
      <LoadingError loading={loading} error={error} />
    )
  };


  
  if (!connected) {
    return(
      <CenteredPage>
        <p>Connecting...</p>
        { ! webSocketCompatable ? (
          <React.Fragment>
            <p>It looks like you've been waiting for a while.</p>
            <p>Your internet connection seems to not support WebSockets which are required for vCue.</p>
            <p>Please make sure all VPN's and proxies are off.</p>
            <p>If you are on a mobile device, try turning wifi off and cellular data on.</p>
          </React.Fragment>
        ) : (
          <></>
        )}
      </CenteredPage>
    )
  };

  return (
    <React.Fragment>
      { data.user.view === "NORMAL" ? (
        <CenteredPage>
          <h2>{`${data.user.name} - ${data.show.name}`}</h2>
          <ButtonGo onClick={go} />
          { data.user.backButton ? (
            <ButtonBack onClick={back} />
          ) : (
            <></>
          )}
        </CenteredPage>
      ) : ( data.user.view === "EXTERNAL" ? (
        <CenteredPage>
          { hasFocus === true ? (
            <></>
          ) : (
            <h1>WINDOW NOT FOCUSED!!!!!!</h1>
          )}
          <h2>{`${data.user.name} - ${data.show.name}`}</h2>
          <GreenTriangle on={goTriangle}/>
          { data.user.backButton ? (
            <RedTriangle on={backTriangle}/>
          ) : (
            <></>
          )}
        </CenteredPage>
      ) : (
        <React.Fragment>
          <BigGreen onClick={go} />
          { data.user.backButton ? (
            <BigRed onClick={back} />
          ) : (
            <></>
          )}
        </React.Fragment>
      ))}
    </React.Fragment>
  );
}

export default Cue;
