import styled from 'styled-components';


const Ul = styled.ul`
  list-style-type: none;
  pargin: 0;
  padding: 0;
  line-height: 2;
  color: #fff;

`;

export default Ul;
