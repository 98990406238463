import React, { useState }  from 'react';
import styled from 'styled-components';

import green from "../../img/green.png";
import green_pressed from "../../img/green_pressed.png";

const Button = styled.button`
  padding: 0;
  margin: 0;
  background: inherit;
  border-style: none;
`;

const Img = styled.img`
  height: 50%;
  width: 50%;
  padding: 0;
  margin: 0;
`;

const ButtonGo = (props) => {

  const [pressed, setPressed] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    props.onClick();
    setPressed(true);
    setTimeout(() => setPressed(false), 1000);
  }

  return (
    <Button>
      <Img
        src={! pressed ? green : green_pressed} 
        alt="green" 
        onClick={handleClick} /> 
    </Button>
  ); 
};

export default ButtonGo;


