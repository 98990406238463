import React, {lazy, Suspense } from 'react'; 
import {importMDX} from 'mdx.macro';
import CenteredPage from '../components/CenteredPage';

const Content = lazy(() => importMDX('../markdown/privacyPolicy.mdx'));

const PrivacyPolicy = () => {
  return (
    <CenteredPage>
      <Suspense fallback={<div>Loading...</div>}>
        <Content />
      </Suspense>
    </CenteredPage>
  )
};

export default PrivacyPolicy;


