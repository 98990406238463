// Here we handle all of the logic for logging in and logging out. 
// In the UI all you do is dispatch login or logout and it removes and adds
// the JWT token for you.
// The only thing NOT done in there is clearing the apollo cache


// Take note that though the permissions are stored in local storage, the API actually verifies the 
// permissions from the DB every single time. 
import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'login',
  initialState: {
    isLoggedIn: !!localStorage.getItem('token'),
    permissions: localStorage.getItem('permissions'),
  },
  reducers: {
    // set isLoggedIn to true
    login: (state, action) => {

      localStorage.setItem('token', action.payload.jwt);
      localStorage.setItem('permissions', action.payload.permissions);
      state.isLoggedIn = true;
      state.permissions = action.payload.permissions;
    },
    // set isLoggedIn to false
    logout: state => {
      // console.log("Logging Out");
      localStorage.removeItem('token');
      localStorage.removeItem('permissions');
      state.isLoggedIn = false;
      state.permissions = null;
    },
  },
});

// Export actions
export const { login, logout } = slice.actions;

// Export selectors
export const selectLogin = state => state.login.isLoggedIn;
export const selectPermissions = state => state.login.permissions;

// Export reducer
export default slice.reducer;

