import React, { useState } from 'react';
import styled from 'styled-components';


const RedOn = styled.button`
  flex: 1 1 33%;
  padding: 0;
  margin: 0;
  border-style: none;
  flex-direction: row;
  width: 100%;
  background: #BB0000;
  justify-content: center;
  align-items: center;
  font-size: 45px;


`;

const RedOff = styled.button`
  flex: 1 1 33%;
  padding: 0;
  margin: 0;
  border-style: none;
  flex-direction: row; 
  width: 100%;
  background: #ff0000;
  justify-content: center;
  align-items: center;
  font-size: 50px;


`;


const BigRed = (props) => {
  const [pressed, setPressed] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    props.onClick();
    setPressed(true);
    setTimeout(() => setPressed(false), 1000);
  }

  return (
    <React.Fragment>
      { ! pressed ? (
        <RedOff
          alt="green" 
          onClick={handleClick} 
        >
          <h1>
            BACK 
          </h1>
        </RedOff>
      ) : (
        <RedOn>
          <h1>
            BACK 
          </h1>
        </RedOn>

      )}
    </React.Fragment>
  );
}



export default BigRed;
