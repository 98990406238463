import React, { useState } from 'react';
import styled from 'styled-components';


const GreenOn = styled.button`
  flex: 1 1 67%;
  padding: 0;
  margin: 0;
  border-style: none;
  flex-direction: row;
  width: 100%;
  background: #00BB00;
  justify-content: center;
  align-items: center;
  font-size: 90px;

`;

const GreenOff = styled.button`
  flex: 1 1 67%;
  padding: 0;
  margin: 0;
  border-style: none;
  flex-direction: row;
  width: 100%;
  background: #00ff00;
  justify-content: center;
  align-items: center;
  font-size: 100px;

`;


const BigGreen = (props) => {
  const [pressed, setPressed] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    props.onClick();
    setPressed(true);
    setTimeout(() => setPressed(false), 1000);
  }

  return (
    <React.Fragment>
      { ! pressed ? (
        <GreenOff 
          alt="green" 
          onClick={handleClick} 
        >
          <h1>
            GO
          </h1>
        </GreenOff>
      ) : (
        <GreenOn>
          <h1>
            GO
          </h1>
        </GreenOn>

      )}
    </React.Fragment>
  );
}



export default BigGreen;
