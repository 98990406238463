import styled from 'styled-components';


const ButtonAsLink = styled.button`
  background: none;
  color: #fff;
  border: none;
  padding: 0px;
  font: inherit;
  text-decoration: none;
  cursor: pointer;

  :hover {
    color: #BBB;
  }

  :active {
    color: #999999;
  }
`;


export default ButtonAsLink;
