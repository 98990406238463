import React, { useEffect, useState }  from 'react';
import CenteredPage from '../components/CenteredPage';
import { useDispatch } from 'react-redux';

import { logout } from '../redux/loginSlice';

import socketConnect from '../socket-connect';

let socket;

const Latency = (props) => {
  const [connected, setConnected] = useState(false);
  const [ webSocketCompatable, setWebsocketCompatable ] = useState(true);

  const [ latencies, setLatencies ] = useState([]);
  const [ stats, setStats ] = useState({
    average: 0,
    pings: 0,
    last: 0,
    stdDev: 0,
  });

  const dispatch = useDispatch();

  // Hook for connection
  useEffect(() => {
    // connect to the socket
    socket = socketConnect();

    // Set the time out before the no Websockets message displays
    setTimeout( () => {
      setWebsocketCompatable(false)
    }, 7000 );


    // On end, disconnted from socket.
    // This prevents a memory leak
    return () => {
      socket.disconnect();
    }
  }, []);

  // Hook for all socket "on" 
  useEffect(() => {
    socket.on('connect', () => {
      setConnected(true);
      console.log(`connnected: ${socket.id}`);
    });

    socket.on('error', (error) => {
      console.log(error)
      console.log("lalala");
    });
    
    socket.on('reconnecting', () => {
      setConnected(false);
    });

    socket.on('unauthorized', (error ) => {
      console.log(error);
      if (error.data.type === 'UnauthorizedError' || error.data.code === 'invalid_token') {
        console.log('User Token has expired');
        dispatch(logout);
      }
    });
 
    // instantiate variables
    let current;
    let stdDev;
    let average;

    socket.on('pong', ms => {
      // Copy Latencies
      current = latencies;
      current.push(ms);
      if (current.length >= 101) {
        current.shift();
      }
      // Calculate average
      average = (current.reduce( (a,b) => (a+b), 0))/current.length;
      // Calculate standard deviation
      stdDev = Math.sqrt(current.reduce( (acu, x) => (acu + (x-average)^2), 0)/current.length);
      // push the current latencies into state
      setLatencies(current);
      // push current stats into state
      setStats({
        average: average,
        last: ms,
        pings: current.length,
        stdDev: stdDev,
      })
    });
    
  },[dispatch, latencies]);
 

  if (!connected) {
    return(
      <CenteredPage>
        <p>Connecting...</p>
        { ! webSocketCompatable ? (
          <React.Fragment>
            <p>It looks like you've been waiting for a while.</p>
            <p>Your internet connection seems to not support WebSockets which are required for vCue.</p>
            <p>Please make sure all VPN's and proxies are off.</p>
            <p>If you are on a mobile device, try turning wifi off and cellular data on.</p>
          </React.Fragment>
        ) : (
          <></>
        )}
      </CenteredPage>
    )
  };

  return (
    <CenteredPage>
      <h2>Latency</h2>
      <h3>{`Number of Pings: ${stats.pings}`}</h3>
      <h3>{`Average: ${stats.average}ms`}</h3>
      <h3>{`Last Ping: ${stats.last}ms`}</h3>
      <h3>{`Standard Deviation: ${stats.stdDev}ms`}</h3>
      <p>These number are calculated from pinging the server from your device.</p>
      <p>They are the round trip time.</p>
      <p>They are not the latencies in every location.</p>
    </CenteredPage>
  )
 
}

export default Latency;

