import { gql } from '@apollo/client';

export const NEW_USER = gql`
  mutation newUser($name: String!, $permissions: String!, $password: String!, $show: ID, $backButton: Boolean, $view: String) {
    newUser(name: $name, permissions: $permissions, password: $password, show: $show, backButton: $backButton, view: $view)
  }
`;

export const REMOVE_USER_BY_ID = gql`
  mutation removeUserById ($id: ID!) {
    removeUserById(id: $id)
  }
`;


export const UPDATE_USER = gql`
  mutation updateUser ($id: ID, $name: String, $permissions: String, $password: String, $backButton: Boolean, $view: String) {
    updateUser(id: $id, name: $name, permissions: $permissions, password: $password, backButton: $backButton, view: $view) 
  }
`;

export const REMOVE_SHOW_BY_ID = gql`
  mutation removeShowById ($id: ID!) {
    removeShowById(id: $id)
  }
`;

export const NEW_SHOW = gql`
  mutation newShow($name: String!) {
    newShow(name: $name) {
      _id
      name
    }
  }
`;

export const UPDATE_SHOW = gql`
  mutation updateShow($id: ID!, $name: String) {
    updateShow(id: $id, name: $name)
  }
`;

export const NEW_ERROR_LOG = gql`
  mutation newErrorLog($user_id: ID, $platform: String, $error: String, $info: String) {
    newErrorLog(user_id: $user_id, platform: $platform, error: $error, info: $info)
  }
`;

export const NEW_OPERATION_LOG = gql`
  mutation newOperationLog($user_id: ID, $platform: String, $operation: String, $info: String) {
    newOperationLog(user_id: $user_id, platform: $platform, operation: $operation, info: $info)
  }
`;

export const GET_INIT_DATA = gql`
  mutation signIn ($username: String!, $password: String!) {
    signIn(username: $username, password: $password){
      user {
        _id
        name
        username
        permissions
        shows {
          _id
          name
        }
      }
      jwt
    }
  }
`;

