import React, { useState } from 'react';
import { useMutation } from '@apollo/client';

import { NEW_USER } from '../gql/mutation'; 
import { GET_SHOW } from '../gql/query';

import CenteredPage from '../components/CenteredPage';
import Form from '../components/Form';
import Button from '../components/Button';
import Dropdown from '../components/Drowpdown';
import { ExtraPadding } from '../components/DivUtility';

const userTypes = [ 
  { _id: "PRESENTER" , name: "Presenter"}, 
  { _id: "OPERATOR", name: "Operator"}  
];
const viewTypes = [
  { _id: "NORMAL", name: "Normal" },
  { _id: "SIMPLE", name: "Simple" },
  { _id: "EXTERNAL", name: "External Cue" },
];


const AddUser = (props)=> {
  const [ show ] = props.match.params.id.split("-");

  const [ values, setValues ] = useState({
    name: "",
    password: "",
    permissions: "",
    view: "",

  });

  const [ errorMessage, setErrorMesssage ] = useState("");
  const [ backButton, setBackButton ] = useState(false);


  const [ newUser ] = useMutation(NEW_USER, {
    refetchQueries: [{ query: GET_SHOW, variables: { showId: show }}],
    awaitRefetchQueries: true,
    onError: err => {
      console.log(err);
      if (err.toString().includes("E1100")) {
        setErrorMesssage(`Error Creating User: Name must be unique`);
      } else {
        setErrorMesssage(`Error Creating User`);
      }
    },
    onCompleted: ()=> {
      props.history.push(`/show/${show}`);
    },
  });



  const onChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  return (
    <CenteredPage>
      <h2>Add User</h2>
      <h3>{errorMessage}</h3>
      <Form onSubmit={(e) => {
        e.preventDefault();

        newUser({
          variables: {
            name: values.name,
            password: values.password,
            permissions: values.permissions,
            show: show,
            backButton: backButton,
            view: values.view,
          }
        });
        
      }}
      >
        <label htmlFor="name">Name:</label>
        <input
          required
          type="text"
          id="name"
          name="name"
          placeholder="name"
          onChange={onChange}
        />
        <label htmlFor="password">Password:</label>
        <input
          required
          type="text"
          id="password"
          name="password"
          placeholder="password"
          onChange={onChange}
        />
        <ExtraPadding>
          <h3>Select User Type</h3>
          <Dropdown
            title={"User"}
            options={userTypes}
            name="permissions"
            onChange={onChange}
          />
        </ExtraPadding>
      { values.permissions === "PRESENTER" ? (
          <React.Fragment>
            <ExtraPadding>
              <h3>Select User View</h3>
              <Dropdown
                title={"View"}
                options={viewTypes}
                name="view"
                onChange={onChange}
              />
            </ExtraPadding>

            <label htmlFor="backButton">Back Button?</label>
            <input
              type="checkbox"
              id="backButton"
              name="backButton"
              value={false}
              onChange={() => setBackButton(!backButton)}
            />
          </React.Fragment>

        ) : (
          <></>
        )}
                <Button type="submit">Submit</Button>
      </Form>
    </CenteredPage>
  )
}

export default AddUser;


