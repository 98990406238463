import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const FooterBar = styled.footer`
  height: 40px;
  left: 0;
  bottom: 0;
  width: 100%;
  background: #111111;
  display: flex;
  justify-content: space-around;
  align-items: center;

`;

const FooterContent = styled.div`
  margin: 0;
  padding: 10px;
  color: #A5A5A5;
  font-size: 11px;

  a {
    text-decoration: none;
    color: #A5A5A5;
    font-size: 11px;
  }

  a:hover {
    color: #fff;
  }
`;


const Footer = () => {
  return (
    <FooterBar>
      <FooterContent>
        {/*Eventually make this a link*/}
        <Link to="/privacypolicy">Privacy Policy</Link>
      </FooterContent>
    </FooterBar>
  )
}

export default Footer;
